<template>
  <section class="tracksipment section--shadow-0">
    <div class="container">
      <div class="row">

      </div>
      <div class="row">
        <div class="col-md-9 col-sm-9">
          <div class="panel panel-default section">
            <div class="panel-body">

            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 margbtm50">
          <SidebarWidget show-profile/>
        </div>
      </div>
      <div class="row quote1top">

        <div class="col-md-12 col-sm-12">
          <div class="panel panel-default section">
            <div class="panel-body table-responsive">

              <h5>{{ getTranslation('applications') }} - {{ items.length }}</h5>


              <table class="table-3 table-responsive">

                <thead class="table-3__head">
                <tr class="table-3__row">
                  <th class="table-3__cell  table-3__hcell" style="width: 20%">{{ getTranslation('title') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 8%">{{ getTranslation('serviceCategory') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 5%">{{ getTranslation('pieces') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 8%">{{ getTranslation('weight') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 8%">{{ getTranslation('volume') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 8%">{{ getTranslation('price') }}</th>
                  <th class="table-3__cell  table-3__hcell" style="width: 8%">{{ getTranslation('trackingStatus') }}</th>
                </tr>
                </thead>

                <tbody class="table-3__body">
                <tr v-for="item in items" v-bind:key="item.number" @click="clickApp(item)" class="table-3__row table__link-row">
                  <td class="table-3__cell">{{ item.title }}</td>
                  <td class="table-3__cell">{{ getTranslation(item.serviceCategory.slug) }}</td>
                  <td class="table-3__cell">{{ item.totals.totalQuantity }}</td>
                  <td class="table-3__cell">{{ item.totals.totalWeight }} {{ item.totals.w_unit }}</td>
                  <td class="table-3__cell">{{ item.totals.totalVolume }} {{ item.totals.v_unit }}<sup>3</sup></td>
                  <td v-if="item.provider" class="table-3__cell">{{ item.provider.totalPrice }} {{ item.provider.currency }}</td>
                  <td class="table-3__cell"><span v-if="item.lastTracking" >{{ item.lastTracking.status.title }}</span></td>
                </tr>
                </tbody>
              </table>

            </div>
            <CPagination
                v-if="pagination.last_page > 1"
                class="mt-5"
                :active-page.sync="pagination.current_page"
                :pages="pagination.last_page"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SidebarWidget from "./components/SidebarWidget";
import helpersMixin from "../mixins/helpers";

export default {
  name: "AppHistory",
  components: { SidebarWidget },
  mixins:[helpersMixin],
  data() {
    return {
      items: [],
      pagination: {
        total: 1,
        last_page: 1,
        current_page: 1
      },
    }
  },
  created() {
    this.fetchItems();
    this.setPortalHeadings({
      title: this.getTranslation('appHistory'),
      breadcrumbs: [
        {
          label: this.getTranslation('home'),
          route: '/'
        },
        {
          label: this.getTranslation('profile'),
          route: {name:'portal.client.account'}
        },
        {
          label: this.getTranslation('appHistory'),
        }
      ]
    });
  },
  methods: {
    fetchItems() {
      this.axios.get(this.$urls.portal.clientApps.url.replace(':id', this.$store.getters['user/getPortalUser'].client.id))
          .then(response => {
            this.items = response.data.data.items;
            this.pagination = response.data.data.pagination;
          }).catch(() => {
      });
    },
    countTotals(items){

    }
  }
}
</script>

<style scoped>

</style>