<template>
  <div class="tracksidebar section" :class="addClasses">
    <div class="widget widget_categories">
      <ul>
        <li>
          <a href="javascript:void(0)" @click="()=>{$router.push({name:'portal.client.profile.apps'}).catch(()=>{})}" class="widget-app-history">
            <i class="fas fa-clipboard-list"></i>{{ getTranslation('appHistory') }}
          </a>
        </li>
        <!--        <li>-->
        <!--          <a href="javascript:void(0)" class="widget-finances">-->
        <!--            <i class="fas fa-money-check-alt"></i>{{ getTranslation('finances') }}-->
        <!--          </a>-->
        <!--        </li>-->
        <li v-if="showProfile">
          <a href="javascript:void(0)" @click="()=>{ $router.push({name:'portal.client.account'}).catch(()=>{}) }" class="widget-finances">
            <i class="flaticon-people"></i>{{ getTranslation('profile') }}
          </a>
        </li>
      </ul>

      <InvoiceTarifComponent :app="app" />

    </div>
  </div>
</template>

<script>
import InvoiceTarifComponent from "@/views/portal/components/InvoiceTarifComponent";
export default {
  name: "SidebarWidget",
  components: { InvoiceTarifComponent },
  props: {
    addClasses: {
      type: String
    },
    app: {
      type: Object,
      default: () => {
        return {
          number: null
        }
      }
    },
    showProfile: {
      type: Boolean,
      default: false
    },
  },


}
</script>

<style scoped>

</style>